<template>
  <heard-and-footer>
    <title-name slot="title" my-name="企业注册"
                :my-background="require('../../assets/image/huiyuanjianjie.png')"></title-name>
    <div slot="content">
      <left-and-right>
        <div slot="leftDiv">
          <div>
            <form>
              <div class="item">
                <label>企业名称：</label>
                <input v-model="form.name" required type="text">
              </div>
              <div class="item">
                <label>企业联系人：</label>
                <input v-model="form.juridicalPersonName" required type="text">
              </div>
              <div class="item">
                <label>电话（用于登录）：</label>
                <input v-model="form.phone" required type="text">
              </div>
              <div class="item">
                <label>密码：</label>
                <input v-model="form.password" required type="password">
              </div>
              <div class="item">
                <label>确认密码：</label>
                <input required v-model="pwd" @blur="password" type="password">
                <span style="font-size: 12px;color: red;margin-left: 10px;display: none" id="message">两次密码不统一</span>
              </div>
              <div class="item">
                <label>企业头像：</label>
                <input style="display: none" multiple="multiple" accept="image/*" @change="selectImg" id="uploadButton"
                       class="upload" type="file">
                <div class="uploadImg" @click="upload">
                  <img id="img" style="display: none" src="">
                  <img src="../../assets/image/icon/add.png" id="ico" class="ico">
                </div>
              </div>
              <input type="submit" class="enrollButton" @click="enroll" value="注册">
            </form>
          </div>
          <div id="toast" class="toast">
            <img src="../../assets/image/icon/success.png">
            注册成功!
          </div>
        </div>
        <div slot="rightDiv">
          <card ref="card">
            <div slot="heard" class="search">
              <input placeholder="搜索">
              <i><img src="../../assets/image/icon/search.png"></i>
            </div>
          </card>
        </div>
      </left-and-right>
    </div>
  </heard-and-footer>
</template>

<script>
import HeardAndFooter from "@/components/public/heardAndFooter";
import LeftAndRight from "@/components/public/leftAndRight";
import card from "@/components/public/card";
import TitleName from "@/components/public/TitleName";

export default {
  name: "enroll",
  data() {
    return {
      pwd: null,
      form: {
        pic: null,
        password: null,
        name: null,
        phone: null,
        juridicalPersonName: null,
      }
    }
  },
  components: {LeftAndRight, HeardAndFooter, card, TitleName},
  mounted() {
    this.getCompaniesList()
  },
  methods: {
    getCompaniesList() {
      this.$http.get("/companies/webSiteGet", {
        params: {
          title: this.title,
          current: 1,
          size: 5
        }
      }).then((res) => {
        for (let i = 0; i < res.data.records.length; i++) {
          res.data.records[i].title = res.data.records[i].name
        }
        let Cards = []
        let Card = {}
        Card.title = '更多企业推荐'
        Card.id = 0
        Card.content = res.data.records
        Cards.push(Card)
        console.log(res.data.records)
        this.$nextTick(() => {
          this.$refs.card.init(Cards)
        })
      })
    },
    upload() {
      document.getElementById("uploadButton").click()
    },
    selectImg() {
      document.getElementById('ico').style.display = 'none'
      let img = document.getElementById('img')
      img.style.display = 'block'
      let fileReader = new FileReader();
      let uploadFile = document.getElementById("uploadButton").files[0]
      //console.log(uploadFile)
      fileReader.readAsDataURL(uploadFile)
      fileReader.onload = function () {
        img.src = this.result
      }
      let param = new FormData()
      param.append('file', uploadFile)
      let config = {
        headers: {'Content-Type': 'multipart/form-data'}
      };
      this.$http.post('/file/uploadOne', param, config).then((res) => {
        // console.log(res.data)
        this.form.pic = res.data
      })
    },
    password() {
      if (this.pwd != this.form.password) {
        document.getElementById('message').style.display = 'inline'
      } else {
        document.getElementById('message').style.display = 'none'
      }
    },
    enroll() {
      if (this.form.password != null && this.form.phone != null && this.form.juridicalPersonName != null
          && this.form.name != null) {
        this.$http.post('/login/companiesEnroll', this.form).then(() => {
          document.getElementById('toast').style.display = 'flex'
          setTimeout(() => {
            document.getElementById('toast').style.display = 'none'
          }, 3000)
        })
      }
    }
  }
}
</script>

<style scoped>
.search {
  width: 100%;
  height: 40px;
  position: relative;
}

.search input {
  height: 38px;
  width: calc(100% - 20px);
  border-radius: 4px;
  padding: 0 10px;
  border: 1px solid #dfdfdf;
}

.search i img {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 12px;
  right: 12px;
}

img {
  width: 100%;
}

.item {
  display: flex;
  align-items: center;
  margin: 10px 0;
  flex-wrap: wrap;
}

.item label {
  width: 150px;
  text-align: right;
}

.item input {
  width: 200px;
  height: 30px;
  line-height: 30px;
  background: #FFFFFF;
  border: 1px #dfdfdf solid;
  border-radius: 3px;
  padding: 0 10px;
}

.uploadImg {
  width: 150px;
  height: 150px;
  border: 1px dashed #dfdfdf;
}

.uploadImg:first-child {
  width: 150px;
  height: 150px;
}

.ico {
  width: 50px;
  height: 50px;
  margin: 50px;
}

.enrollButton {
  width: 100px;
  height: 38px;
  margin-top: 30px;
  margin-left: 150px;
  border: 1px solid #dfdfdf;
  color: #FFFFFF;
  background: linear-gradient(to right, #fc4a1a, #f7b733);
  border-radius: 3px;
}

.toast {
  width: 200px;
  height: 20px;
  position: fixed;
  display: none;
  top: calc(50% - 40px);
  left: calc(50% - 100px);
  z-index: 999;
  background: rgba(0, 164, 151, 0.8);
  color: #FFFFFF;
  padding: 10px;
  text-align: left;
  border-radius: 3px;
  box-shadow: 0 0 10px #dfdfdf;

}

.toast img {
  width: 20px;
  height: 20px;
  margin: 0 10px;
}
</style>